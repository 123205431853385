import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../Layout';

const Tags = ({ pageContext, data }) => {
    const { tag } = pageContext;
    const { edges, totalCount } = data.allMarkdownRemark;
    const tagHeader = `${totalCount} post${
        totalCount === 1 ? '' : 's'
    } tagged with "${tag}"`;

    return (
        <Layout>
            <section className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
                <h1>
                    {tagHeader}
                    <button className="btn-tag center-tag tag-fix-on-mobile">
                        <Link to="/tags">View all tags</Link>
                    </button>
                </h1>
                <hr />
                <div className="blog-list">
                    {edges.map(({ node }, i) => (
                        <div className="blog row" key={i}>
                            <div className="col-xs-12">
                                <Link
                                    to={node.fields.slug}
                                    className="blog-link"
                                >
                                    <h6>{node.frontmatter.date}</h6>
                                    <h2 className="blog-title">
                                        {node.frontmatter.title}
                                    </h2>
                                    <div className="blog-excerpt">
                                        <p>{node.excerpt}</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </Layout>
    );
};

export default Tags;

export const pageQuery = graphql`
    query($tag: String) {
        allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { tags: { in: [$tag] } } }
        ) {
            totalCount
            edges {
                node {
                    fields {
                        slug
                    }
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "MMMM Do YYYY")
                        title
                    }
                }
            }
        }
    }
`;
